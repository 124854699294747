<template>
  <div v-if="fields.length > 0" class="form-builder__group">
    <legend v-if="data.label" class="text-h6 q-my-xs">
      {{ data.label }}
    </legend>

    <div
      class="form-builder__group-fields"
      :class="data.styleClasses || ''"
      :style="data.style || ''"
    >
      <div
        v-for="(field, i) in fields"
        :key="`${field.id}:${field.type}:${i}`"
        class="form-builder__group-field-wrapper row"
        :class="
          typeof field.if !== 'boolean' || field.if
            ? field.wrapperStyleClasses
            : ''
        "
      >
        <div class="col">
          <q-skeleton
            v-if="isLoading"
            :type="field.type === 'btn' ? 'QBtn' : 'QInput'"
          />

          <form-builder-field v-else :ref="field.ref || ''" :field="field" />
        </div>

        <div
          v-if="field.buttons && !isLoading"
          :class="field.btnStyleClasses || ''"
        >
          <q-btn
            v-for="btn in getButtons(field)"
            :disable="field.disabled || !!btn.disabled"
            :key="btn.id"
            :color="btn.color || ''"
            :label="btn.label"
            :icon="btn.icon"
            :size="btn.size || 'md'"
            :class="btn.styleClasses || ''"
            no-caps
            @click="btn.onClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'FormGroup',
  components: {
    FormBuilderField: defineAsyncComponent(() => import('./FormBuilderField.vue'))
  },
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    activatedFields: {
      type: Array,
      default () {
        return null
      }
    },
    data: {
      type: Object,
      default () {
        return {
          label: '',
          style: '',
          styleClasses: '',
          fields: []
        }
      }
    }
  },
  computed: {
    fields () {
      const fields = this.data.fields.reduce((acc, field) => {
        const state = this.data.entityId
          ? this.data.entityState
          : 'create'

        const access = this.getAccess(field, this.data.entity, state)

        if (access.read && (typeof field.if !== 'boolean' || field.if)) {
          acc.push({
            ...field,
            ...access.options,
            disabled: !access.write || !!field.disabled,
            access
          })
        }

        return acc
      }, [])

      if (!this.activatedFields) {
        return fields
      }

      const filteredFields = fields.filter(({ field }) => {
        if (!field) {
          return true
        }

        return this.activatedFields.includes(field)
      })

      return filteredFields
    }
  },
  methods: {
    getAccess (data, entity, entityState) {
      if (!entity || !data.field) {
        return this.$formBuilder.fullAccess
      }

      return this.$formBuilder.getAccess(entity, entityState, data.field)
    },
    getButtons (field) {
      return field.buttons.filter(x => typeof x.if !== 'boolean' || x.if)
    }
  }
}
</script>
